import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent, Subject } from 'rxjs';
import { UserDetails } from '../model/user-details.model';
import { Cart, CartTotal } from '../model/cart.model';
import { Address } from '../model/address.model';
import { PrivilegesMenu } from '../model/adminPrivilegesList.model';
import { Sidebar } from '../model/b2b/orders/admin-header.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor() {
    fromEvent(window, 'resize').pipe(
      distinctUntilChanged()
    ).subscribe(() => {
      this.checkScreenWidth();
    });
  }
  private screenWidth = new BehaviorSubject<number>(window.innerWidth)
  screenWidth$ = this.screenWidth.asObservable()
  checkScreenWidth() {
    this.screenWidth.next(window.innerWidth)
  }

  // private isLoading = new Subject<boolean>();
  // loader$ = this.isLoading.asObservable();

  // shimmerLoaderUpdate(isLoading: boolean) {
  //   this.isLoading.next(isLoading);
  // }

  private isShimmerLoading = new BehaviorSubject<boolean>(false);
  shimmerLoader$ = this.isShimmerLoading.asObservable();

  shimmerLoaderUpdate(isShimmerLoading: boolean) {
    this.isShimmerLoading.next(isShimmerLoading)
  }

  private isCartSideBarACtive = new Subject<boolean>();
  cartSideBar$ = this.isCartSideBarACtive.asObservable();

  showCartSideBar(isCartSideBarACtive: boolean) {
    this.isCartSideBarACtive.next(isCartSideBarACtive);
  }

  private isCartUpdated = new Subject<boolean>();
  cartUpdated$ = this.isCartUpdated.asObservable();

  sendCartUpdated(isCartUpdated: boolean) {
    this.isCartUpdated.next(isCartUpdated);
  }

  private cartDetails = new BehaviorSubject<any>(null);
  cartDetails$ = this.cartDetails.asObservable();

  sendCartDetails(cartDetails: Cart[]) {
    this.cartDetails.next(cartDetails);
  }

  private cartTotal = new BehaviorSubject<CartTotal>(new CartTotal());
  cartTotal$ = this.cartTotal.asObservable();

  sendCartTotal(cartTotal: CartTotal) {
    this.cartTotal.next(cartTotal);
  }

  private userDetails = new BehaviorSubject<UserDetails>(new UserDetails());
  user$ = this.userDetails.asObservable();

  sendUserDetails(user: UserDetails) {
    this.userDetails.next(user);
  }

  private loadUser = new Subject<boolean>();
  loadUser$ = this.loadUser.asObservable();

  reloadUser(loadUser: boolean) {
    this.loadUser.next(loadUser);
  }

  private guestUser = new Subject<boolean>();
  guestUser$ = this.guestUser.asObservable();

  reloadGuestUser(guestUser: boolean) {
    this.guestUser.next(guestUser);
  }

  private guestUserFetch = new BehaviorSubject<boolean>(false);
  guestUserFetch$ = this.guestUserFetch.asObservable();

  isGuestUserFetch(guestUserFetch: boolean) {
    this.guestUserFetch.next(guestUserFetch);
  }

  private tokenFetch = new BehaviorSubject<boolean>(false);
  tokenFetch$ = this.tokenFetch.asObservable();

  isTokenFetch(tokenFetch: boolean) {
    this.tokenFetch.next(tokenFetch);
  }

  private loadUserAddress = new Subject<boolean>();
  loadUserAddress$ = this.loadUserAddress.asObservable();

  reloadUserAddress(loadUserAddress: boolean) {
    this.loadUserAddress.next(loadUserAddress);
  }

  private userAddressDetails = new BehaviorSubject<any>(null);
  userAddressDetails$ = this.userAddressDetails.asObservable();

  sendUserAddressDetails(userAddressDetails: Address[]) {
    this.userAddressDetails.next(userAddressDetails);
  }

  // private adminPrivilegesList = new BehaviorSubject<any>(null);
  // adminPrivilegesList$ = this.adminPrivilegesList.asObservable();

  // sendAdminPrivilegesList(adminPrivilegesList: any) {
  //   this.adminPrivilegesList.next(adminPrivilegesList);
  // }
  private privilegesMenu = new BehaviorSubject<PrivilegesMenu[]>([]);
  privilegesMenu$ = this.privilegesMenu.asObservable();

  sendPrivilegesMenu(privilegesMenu: PrivilegesMenu[]) {
    this.privilegesMenu.next(privilegesMenu);
  }

  private menuList = new BehaviorSubject<Sidebar>(new Sidebar());
  menuList$ = this.menuList.asObservable();

  sendMenuList(menuList: Sidebar) {
    this.menuList.next(menuList);
  }
}
